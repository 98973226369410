/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import type { Channel } from 'pusher-js';
import { Observable } from 'rxjs';

import { ChannelType } from './channel.type';

export type ChannelName = `${ChannelType}-${string}`;
export type ChannelEventType =
  | 'pusher:subscription_succeeded'
  | 'pusher:subscription_error'
  | 'pusher:member_added'
  | 'pusher:member_removed'
  | 'pusher:cache_miss'
  | string;

export abstract class BaseChannel<TName extends ChannelName = ChannelName> {
  readonly #channel: Channel;
  abstract get type(): ChannelType;

  constructor(channel: Channel) {
    this.#channel = channel;
  }

  get name(): TName {
    return this.#channel.name as TName;
  }

  on<TEvent extends ChannelEventType, TData>(event: TEvent, callback: (data: TData) => void): void {
    this.#channel.bind(event, callback);
  }

  off<TEvent extends ChannelEventType>(event: TEvent): void {
    this.#channel.unbind(event);
  }

  on$<TEvent extends ChannelEventType, TData>(event: TEvent): Observable<TData> {
    return new Observable(subscriber => {
      this.#channel.bind(event, (data: TData) => {
        subscriber.next(data);
      });

      return subscriber.add(() => {
        this.#channel.unbind(event);
      });
    });
  }

  emit<TEvent extends string, TData>(event: TEvent, data: TData): void {
    this.#channel.emit(event, data);
  }

  dispose(): void {
    this.#channel.unsubscribe();
    this.#channel.disconnect();
  }
}
